import { default as _404A9m6cHdpbDMeta } from "/home/forge/product-module.34ml.com/pages/404.vue?macro=true";
import { default as accountShXIGItF2kMeta } from "/home/forge/product-module.34ml.com/pages/account.vue?macro=true";
import { default as bundlesHBUc04SUQDMeta } from "/home/forge/product-module.34ml.com/pages/bundles.vue?macro=true";
import { default as cartyvZialW6uXMeta } from "/home/forge/product-module.34ml.com/pages/cart.vue?macro=true";
import { default as indexIhy0Vu3UwzMeta } from "/home/forge/product-module.34ml.com/pages/checkout/index.vue?macro=true";
import { default as paymentBPWMdLJfNUMeta } from "/home/forge/product-module.34ml.com/pages/checkout/payment.vue?macro=true";
import { default as contactUsnySZtlMDQ0Meta } from "/home/forge/product-module.34ml.com/pages/contactUs.vue?macro=true";
import { default as _91id_93hACEnqYVcTMeta } from "/home/forge/product-module.34ml.com/pages/custom/[id].vue?macro=true";
import { default as faqY5izV9RD1rMeta } from "/home/forge/product-module.34ml.com/pages/faq.vue?macro=true";
import { default as indexxMP19XNGB3Meta } from "/home/forge/product-module.34ml.com/pages/index.vue?macro=true";
import { default as logincGe3tdnsuAMeta } from "/home/forge/product-module.34ml.com/pages/login.vue?macro=true";
import { default as order_45placedi8LzhHN5W5Meta } from "/home/forge/product-module.34ml.com/pages/order-placed.vue?macro=true";
import { default as contentqJXD4fQwehMeta } from "/home/forge/product-module.34ml.com/pages/pages/[key]/content.vue?macro=true";
import { default as payment_45failedz5YyzmNmDWMeta } from "/home/forge/product-module.34ml.com/pages/payment-failed.vue?macro=true";
import { default as processing_45transactionCtmAvpIl5FMeta } from "/home/forge/product-module.34ml.com/pages/processing-transaction.vue?macro=true";
import { default as _91id_93xBK1job2HeMeta } from "/home/forge/product-module.34ml.com/pages/products/[id].vue?macro=true";
import { default as indexBf1CQmvIwdMeta } from "/home/forge/product-module.34ml.com/pages/products/index.vue?macro=true";
import { default as registerJOXrA2PbvpMeta } from "/home/forge/product-module.34ml.com/pages/register.vue?macro=true";
import { default as resetlqUtg4hddzMeta } from "/home/forge/product-module.34ml.com/pages/reset.vue?macro=true";
import { default as staticFXrzOkbsEYMeta } from "/home/forge/product-module.34ml.com/pages/static.vue?macro=true";
import { default as verifymN0eKOSUoSMeta } from "/home/forge/product-module.34ml.com/pages/verify.vue?macro=true";
import { default as wishlistrNxGgx4IwiMeta } from "/home/forge/product-module.34ml.com/pages/wishlist.vue?macro=true";
import { default as tenantLoginEIAAym2wKCMeta } from "/home/forge/product-module.34ml.com/components/tenantLogin.vue?macro=true";
export default [
  {
    name: _404A9m6cHdpbDMeta?.name ?? "404",
    path: _404A9m6cHdpbDMeta?.path ?? "/404",
    meta: _404A9m6cHdpbDMeta || {},
    alias: _404A9m6cHdpbDMeta?.alias || [],
    redirect: _404A9m6cHdpbDMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/404.vue").then(m => m.default || m)
  },
  {
    name: accountShXIGItF2kMeta?.name ?? "account",
    path: accountShXIGItF2kMeta?.path ?? "/account",
    meta: accountShXIGItF2kMeta || {},
    alias: accountShXIGItF2kMeta?.alias || [],
    redirect: accountShXIGItF2kMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/account.vue").then(m => m.default || m)
  },
  {
    name: bundlesHBUc04SUQDMeta?.name ?? "bundles",
    path: bundlesHBUc04SUQDMeta?.path ?? "/bundles",
    meta: bundlesHBUc04SUQDMeta || {},
    alias: bundlesHBUc04SUQDMeta?.alias || [],
    redirect: bundlesHBUc04SUQDMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/bundles.vue").then(m => m.default || m)
  },
  {
    name: cartyvZialW6uXMeta?.name ?? "cart",
    path: cartyvZialW6uXMeta?.path ?? "/cart",
    meta: cartyvZialW6uXMeta || {},
    alias: cartyvZialW6uXMeta?.alias || [],
    redirect: cartyvZialW6uXMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: indexIhy0Vu3UwzMeta?.name ?? "checkout",
    path: indexIhy0Vu3UwzMeta?.path ?? "/checkout",
    meta: indexIhy0Vu3UwzMeta || {},
    alias: indexIhy0Vu3UwzMeta?.alias || [],
    redirect: indexIhy0Vu3UwzMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: paymentBPWMdLJfNUMeta?.name ?? "checkout-payment",
    path: paymentBPWMdLJfNUMeta?.path ?? "/checkout/payment",
    meta: paymentBPWMdLJfNUMeta || {},
    alias: paymentBPWMdLJfNUMeta?.alias || [],
    redirect: paymentBPWMdLJfNUMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: contactUsnySZtlMDQ0Meta?.name ?? "contactUs",
    path: contactUsnySZtlMDQ0Meta?.path ?? "/contactUs",
    meta: contactUsnySZtlMDQ0Meta || {},
    alias: contactUsnySZtlMDQ0Meta?.alias || [],
    redirect: contactUsnySZtlMDQ0Meta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/contactUs.vue").then(m => m.default || m)
  },
  {
    name: _91id_93hACEnqYVcTMeta?.name ?? "custom-id",
    path: _91id_93hACEnqYVcTMeta?.path ?? "/custom/:id()",
    meta: _91id_93hACEnqYVcTMeta || {},
    alias: _91id_93hACEnqYVcTMeta?.alias || [],
    redirect: _91id_93hACEnqYVcTMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/custom/[id].vue").then(m => m.default || m)
  },
  {
    name: faqY5izV9RD1rMeta?.name ?? "faq",
    path: faqY5izV9RD1rMeta?.path ?? "/faq",
    meta: faqY5izV9RD1rMeta || {},
    alias: faqY5izV9RD1rMeta?.alias || [],
    redirect: faqY5izV9RD1rMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexxMP19XNGB3Meta?.name ?? "index",
    path: indexxMP19XNGB3Meta?.path ?? "/",
    meta: indexxMP19XNGB3Meta || {},
    alias: indexxMP19XNGB3Meta?.alias || [],
    redirect: indexxMP19XNGB3Meta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logincGe3tdnsuAMeta?.name ?? "login",
    path: logincGe3tdnsuAMeta?.path ?? "/login",
    meta: logincGe3tdnsuAMeta || {},
    alias: logincGe3tdnsuAMeta?.alias || [],
    redirect: logincGe3tdnsuAMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: order_45placedi8LzhHN5W5Meta?.name ?? "order-placed",
    path: order_45placedi8LzhHN5W5Meta?.path ?? "/order-placed",
    meta: order_45placedi8LzhHN5W5Meta || {},
    alias: order_45placedi8LzhHN5W5Meta?.alias || [],
    redirect: order_45placedi8LzhHN5W5Meta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/order-placed.vue").then(m => m.default || m)
  },
  {
    name: contentqJXD4fQwehMeta?.name ?? "pages-key-content",
    path: contentqJXD4fQwehMeta?.path ?? "/pages/:key()/content",
    meta: contentqJXD4fQwehMeta || {},
    alias: contentqJXD4fQwehMeta?.alias || [],
    redirect: contentqJXD4fQwehMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/pages/[key]/content.vue").then(m => m.default || m)
  },
  {
    name: payment_45failedz5YyzmNmDWMeta?.name ?? "payment-failed",
    path: payment_45failedz5YyzmNmDWMeta?.path ?? "/payment-failed",
    meta: payment_45failedz5YyzmNmDWMeta || {},
    alias: payment_45failedz5YyzmNmDWMeta?.alias || [],
    redirect: payment_45failedz5YyzmNmDWMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: processing_45transactionCtmAvpIl5FMeta?.name ?? "processing-transaction",
    path: processing_45transactionCtmAvpIl5FMeta?.path ?? "/processing-transaction",
    meta: processing_45transactionCtmAvpIl5FMeta || {},
    alias: processing_45transactionCtmAvpIl5FMeta?.alias || [],
    redirect: processing_45transactionCtmAvpIl5FMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/processing-transaction.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xBK1job2HeMeta?.name ?? "products-id",
    path: _91id_93xBK1job2HeMeta?.path ?? "/products/:id()",
    meta: _91id_93xBK1job2HeMeta || {},
    alias: _91id_93xBK1job2HeMeta?.alias || [],
    redirect: _91id_93xBK1job2HeMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexBf1CQmvIwdMeta?.name ?? "products",
    path: indexBf1CQmvIwdMeta?.path ?? "/products",
    meta: indexBf1CQmvIwdMeta || {},
    alias: indexBf1CQmvIwdMeta?.alias || [],
    redirect: indexBf1CQmvIwdMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerJOXrA2PbvpMeta?.name ?? "register",
    path: registerJOXrA2PbvpMeta?.path ?? "/register",
    meta: registerJOXrA2PbvpMeta || {},
    alias: registerJOXrA2PbvpMeta?.alias || [],
    redirect: registerJOXrA2PbvpMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resetlqUtg4hddzMeta?.name ?? "reset",
    path: resetlqUtg4hddzMeta?.path ?? "/reset",
    meta: resetlqUtg4hddzMeta || {},
    alias: resetlqUtg4hddzMeta?.alias || [],
    redirect: resetlqUtg4hddzMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: staticFXrzOkbsEYMeta?.name ?? "static",
    path: staticFXrzOkbsEYMeta?.path ?? "/static",
    meta: staticFXrzOkbsEYMeta || {},
    alias: staticFXrzOkbsEYMeta?.alias || [],
    redirect: staticFXrzOkbsEYMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/static.vue").then(m => m.default || m)
  },
  {
    name: verifymN0eKOSUoSMeta?.name ?? "verify",
    path: verifymN0eKOSUoSMeta?.path ?? "/verify",
    meta: verifymN0eKOSUoSMeta || {},
    alias: verifymN0eKOSUoSMeta?.alias || [],
    redirect: verifymN0eKOSUoSMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: wishlistrNxGgx4IwiMeta?.name ?? "wishlist",
    path: wishlistrNxGgx4IwiMeta?.path ?? "/wishlist",
    meta: wishlistrNxGgx4IwiMeta || {},
    alias: wishlistrNxGgx4IwiMeta?.alias || [],
    redirect: wishlistrNxGgx4IwiMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: tenantLoginEIAAym2wKCMeta?.name ?? "tenantLogin",
    path: tenantLoginEIAAym2wKCMeta?.path ?? "/tenantLogin",
    meta: tenantLoginEIAAym2wKCMeta || {},
    alias: tenantLoginEIAAym2wKCMeta?.alias || [],
    redirect: tenantLoginEIAAym2wKCMeta?.redirect,
    component: () => import("/home/forge/product-module.34ml.com/components/tenantLogin.vue").then(m => m.default || m)
  }
]