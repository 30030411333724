export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"description","name":"description","content":"Discover a wide selection of premium products. Shop exclusive offers, explore our latest collections, and elevate your shopping experience today."},{"hid":"og:title","property":"og:title","content":"OG title"},{"hid":"og:description","property":"og:description","content":"OG description"},{"hid":"og:image","property":"og:image","content":"/og-image.jpg"}],"link":[],"style":[],"script":[],"noscript":[],"title":"Welcome to Your One-Stop Shop for Premium Products","titleTemplate":"%s"}

export const appBuildAssetsDir = "/_nuxt/"

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false