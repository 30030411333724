<template>
  <NuxtLoadingIndicator :color="primaryColor" :height="5" />
  <tenant-login v-if="!$appConfig && env.public.env.VERSION == 'PREVIEW'" />

  <div class="app relative h-full" :class="` theme-${$appConfig ? $theme : 1}`">
    <NuxtLayout v-if="$appConfig">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import tenantConfigJson from "~/tenant-config.json";
import tenantLogin from "./components/tenantLogin.vue";
import useLocale from "./composables/Common/useLocale";
import useGAnalytics from "~/composables/Common/useGAnalytics";

const nuxtApp = useNuxtApp();
const env = useRuntimeConfig();
const { initLocale } = useLocale();
const { getGAnalyticsScript } = useGAnalytics();

let primaryColor = "";
if (env.public.env.VERSION === "PREVIEW") {
  primaryColor = nuxtApp.$color as string;
} else if (env.public.env.VERSION === "PUBLISHED") {
  primaryColor = tenantConfigJson.primaryColor;
}

useHead(() => ({
  style: [
    {
      id: "my-style",
      innerHTML: `:root {--main: ${primaryColor};}.bg-main { background-color: ${primaryColor} !important; } .border-main { border-color: ${primaryColor} !important;} .text-main { color: ${primaryColor} !important; } .color-main svg { fill: ${primaryColor} !important;}`,
    },
  ],
  script: getGAnalyticsScript,
}));

onMounted(() => {
  initLocale();
});
</script>
